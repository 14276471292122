import React from 'react';
import { Link } from 'gatsby';

export default function Logo() {
  return (
      <main className='py-2 px-2 rounded-sm'>
          <Link to="/">
            <svg className="w-24 lg:w-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.79807 23.629049">
              <path fill="#333" d="M-126.673687 37.74388H28.973275c1.01812 0 1.83833.83361 1.83833 1.86725v57.6199c0 1.03329-.82021 1.86725-1.83833 1.86725h-155.646962c-1.018117 0-1.837972-.83396-1.837972-1.86725v-57.6199c0-1.03364.819855-1.86725 1.837972-1.86725z"/>
              <path fill="#fff" d="M42.267363 23.629046V.176386h9.788172c1.814689 0 3.202517.19297 4.161719.57608.957792.38312 1.73355 1.0982 2.320573 2.14419.587022 1.03469.881944 2.2987.881944 3.79095 0 1.30069-.226131 2.42675-.675217 3.37608-.449439.93839-1.0668 1.70074-1.853494 2.28776-.500239.37324-1.189214.68192-2.061633.92745.698852.28787 1.211439.57574 1.529644.86396.217664.19156.527756.60219.933097 1.2319.415925.62829.692856 1.11336.830792 1.45521l2.838803 6.79908h-6.636809l-3.137605-7.16703c-.398286-.92745-.752475-1.52965-1.063978-1.80799-.42298-.36089-.903816-.54433-1.438628-.54433h-.51823v9.51935zm5.89915-13.94989h2.4765c.267405 0 .786694-.10724 1.55575-.31962.38735-.0963.704144-.34184.945444-.73589.250825-.39405.376061-.84878.376061-1.35996 0-.75812-.19438-1.33808-.582789-1.74343-.388761-.4064-1.120422-.60889-2.191102-.60889h-2.579864ZM62.669207.176386h8.724194c1.719792 0 3.10762.28927 4.16172.86395 1.063977.57608 1.940277 1.403 2.632075 2.47932.691797 1.07738 1.192036 2.33151 1.503538 3.76061.310092 1.42875.467078 2.94217.467078 4.54202 0 2.50789-.234244 4.45381-.701322 5.83988-.456142 1.37548-1.097139 2.53294-1.916994 3.47133-.821267.92745-1.703211 1.54623-2.645834 1.85632-1.288344.42721-2.454275.63923-3.500261.63923h-8.724194zm5.871633 5.31107v12.81429h1.440039c1.226608 0 2.099028-.16404 2.618317-.496.51823-.34043.92463-.92745 1.219552-1.76001.293512-.84173.440973-2.20204.440973-4.07882 0-2.48461-.330553-4.18641-.98672-5.10293-.657225-.91792-1.744486-1.37653-3.267075-1.37653zm24.370594 14.2695h-6.691489l-.919338 3.87209h-6.003925L86.442901.176386h6.403975l7.14375 23.45266h-6.146095zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zM102.200778.176386h5.484636l7.156097 12.9741V.176386h5.535789v23.45266h-5.535789l-7.118702-12.87745v12.87745h-5.522031zm19.747442 0h17.849849v5.79049h-5.987697v17.66217h-5.874455V5.966876h-5.987697z"/>
              <path fill="#1e40af" d="M25.390475-.000004H40.30098v5.48746H25.390475Z"/>
              <path fill="#fa7c23" d="M25.527 17.892526h14.911916v5.48887H25.527Z"/>
              <path fill="#15803d" d="M25.527 9.128116h10.437283v5.48887H25.527Z"/>
              <path fill="#fff" d="m0 .179206 6.722181-.0649 8.437738 22.7778-6.895747.10865zm19.594336 3.77013 4.019902-3.30588-7.298266 19.7612-4.412544-11.0684z"/>
              <path fill="#333" d="M-76.343588-25.87183v-23.45161h9.788525c1.814689 0 3.202516.19297 4.161366.57609.959203.38312 1.733903 1.0982 2.320925 2.14418.587022 1.03364.881945 2.29729.881945 3.79095 0 1.29964-.226131 2.42676-.674159 3.37609-.450497.93733-1.067858 1.69933-1.854552 2.28635-.500239.37465-1.188156.68333-2.061634.92745.699911.28928 1.211086.57715 1.529645.86537.217664.19155.527755.60219.934155 1.23189.414867.6283.691798 1.11337.829734 1.45521l2.838802 6.79803h-6.636808l-3.137605-7.16597c-.398286-.92746-.752475-1.52965-1.063978-1.80799-.42298-.3623-.902758-.54434-1.438628-.54434h-.51823v9.5183zm5.899503-13.94989h2.4765c.267052 0 .786694-.10619 1.55575-.31856.388408-.0963.704144-.3429.946502-.73589.249414-.39406.375003-.84879.375003-1.35996 0-.75812-.19438-1.33809-.582789-1.74308-.38876-.40675-1.120422-.60924-2.191102-.60924h-2.579864Zm14.502341-9.50172h8.724547c1.719792 0 3.107619.28928 4.161719.86396 1.063625.57608 1.940278 1.40299 2.632075 2.47791.691798 1.07879 1.192036 2.33292 1.503186 3.76061.311503 1.4291.467431 2.94216.467431 4.54201 0 2.50931-.234245 4.45382-.700264 5.8413-.457553 1.37548-1.098197 2.53294-1.918053 3.47133-.821266.92745-1.703211 1.54622-2.645833 1.85632-1.287286.42721-2.454275.63817-3.500261.63817h-8.724547zm5.871986 5.30966v12.81571h1.440039c1.226608 0 2.100086-.16404 2.618316-.49741.518231-.33902.924631-.92746 1.219553-1.7586.293511-.84314.440972-2.20204.440972-4.07882 0-2.48461-.329494-4.18782-.986719-5.10293-.657225-.91898-1.744486-1.37795-3.266017-1.37795zm24.370594 14.27092h-6.690431l-.920397 3.87103h-6.002866l7.145161-23.45161h6.403622l7.14375 23.45161h-6.145742zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zm10.521244-14.5094h5.484636l7.156097 12.97411v-12.97411h5.535789v23.45161h-5.535789l-7.117644-12.8778v12.8778h-5.523089zm19.747441 0h17.850908v5.7905h-5.988755v17.66111H9.326377v-17.66111H3.337621z"/>
              <path fill="#1e40af" d="M-93.220124-49.49983h14.910505v5.48746h-14.910505Z"/>
              <path fill="#fa7c23" d="M-93.08254-31.60729h14.910505v5.48887H-93.08254Z"/>
              <path fill="#15803d" d="M-93.08254-40.3717h10.437283v5.48887H-93.08254Z"/>
              <path fill="#333" d="m-118.610598-49.32061 6.72218-.0649 8.437739 22.7764-6.895747.11006zm19.594335 3.77013 4.020961-3.30588-7.299324 19.7612-4.412545-11.0684z"/>
            </svg>
          </Link>
      </main>
  );
}
