import React from 'react';
import fb from '../images/Facebook.png'
import tw from '../images/Twitter.png'
import ins from '../images/Instagram.png'
import { Link } from 'gatsby';


export default function Footer() {
  return (
      <main>
          {/* Footer */}
        <footer className='py-10 bg-verdantblack text-gray-100 text-sm lg:py-20'>
          <div id="container" className='w-10/12 mx-auto flex items-start justify-between sm:w-7/12 lg:w-9/12'>
            <nav id="menu" className=''>
              <h3 className='text-lg text-orange-500 font-bold font-worksans lg:text-2xl'>Menu</h3>
              <div id="menu items container" className='py-2 lg:text-lg'>
                <Link to="/"><p className='font-bold lg:py-1'>Home</p></Link>
                <Link to="/about"><p className='py-3 font-bold lg:py-1'>About</p></Link>
                <Link to="/services"><p className='font-bold lg:py-1'>Services</p></Link>
                <Link to="/projects"><p className='py-3 font-bold lg:py-1'>Projects</p></Link>
                <Link to="/contact"><p className='font-bold lg:py-1'>Contact</p></Link>

                <div id="socials" className='pt-7 flex items-center space-x-1 lg:pt-14'>
                  <div id="fb"><img src={fb} alt="Facebook/Meta logo" className='h-6 w-6'/></div>
                  <div id="tw"><img src={tw} alt="Twitter logo" className='h-6 w-6'/></div>
                  <div id="in"><img src={ins} alt="Instagram logo" className='h-6 w-6'/></div>
                </div>

                {/* <p id="for mobile" className='font-bold font-worksans text-orange-500 lg:hidden'>&copy; Verdant Consulting</p> */}
              </div>
              
            </nav>
            <section id="contacts" className='lg:text-base'>
              <h3 className='text-lg text-orange-500 font-bold font-worksans lg:text-2xl'>Contacts</h3>
              <div id="email container" className='pt-2 pb-3'>
                <h4 className='font-bold lg:text-lg'>Email:</h4>
                <p>info@verdant.ug</p>
              </div>
              
              <h4 className=' font-bold lg:text-lg lg:pt-2'>Call:</h4>
              <p>+256 752 840 101</p>
              <p>+256 782 976 968</p>

              <h4 className='pt-3 font-bold lg:text-lg lg:pt-5'>Location:</h4>
              <p id='location' className=''>Kakungulu Road,<br/> Minister’s Village, <br/>Ntinda Kampala – Uganda</p>
              

            </section>
            
            <section id="request quote" className='hidden lg:block'>
              <div id="the quote input">
                <h3 className='text-2xl text-orange-500 font-bold font-worksans'>Request Quote</h3>
                <form name="verdant quote form" method="POST" data-netlify="true" action="/mailsuccess" className='w-full pt-4'>
                  <input type="hidden" name="form-name" value="verdant quote form" />
                  <input placeholder="Enter your email..." type="email" className='py-2 px-2 text-verdantblack rounded-2xl w-64 focus:outline-none'/>
                </form>
                <div id="submit button"><button type="submit" className='my-2 py-1 px-2 rounded-md bg-green-700 uppercase text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-400 lg:text-sm lg:px-6'>submit</button></div>
              </div>
              <div id="bottom" className='pt-40'>
                
                <p className='font-bold font-worksans text-orange-500 text-base'>&copy; Verdant Consulting</p>
              </div>
                              
            </section>
          </div>
          
                    
        </footer>
        {/* <div className='py-20 sm:py-56'></div> */}
      </main>
  )
}
